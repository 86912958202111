import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/styles/index.scss"; // global css
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;
// 安装BootstrapVue
Vue.use(BootstrapVue);
// 可选，安装BootstrapVue Icon组件插件
Vue.use(IconsPlugin);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
