<template>
  <div id="app">
    <div class="top-box">
      <div class="logo-box">
        <img src="@/assets/bcstars.png" class="logo" alt="" />
      </div>
      <div class="top-list-box">
        <router-link to="/home">
          <keep-alive>
            <div class="top-nav hanle-link">首页</div>
          </keep-alive>
        </router-link>
        <router-link to="/server">
          <keep-alive>
            <div class="top-nav hanle-link">服务平台</div>
          </keep-alive>
        </router-link>
        <router-link to="/about">
          <keep-alive>
            <div class="top-nav hanle-link">关于我们</div>
          </keep-alive>
        </router-link>
        <!-- <router-link to="/case">
          <keep-alive>
            <div class="top-nav hanle-link">成功案例</div>
          </keep-alive>
        </router-link> -->
        <router-link to="/contact">
          <keep-alive>
            <div class="top-nav hanle-link">联系我们</div>
          </keep-alive>
        </router-link>
      </div>
    </div>
    <div ref="scrollDiv"></div>
    <router-view />
    <div class="top-icon-box1">
      <div class="icon hanle-link">
        <div class="icon-box">
          <img
            src="@/assets/tel.png"
            class="img-defalt"
            alt=""
            @mouseover="telShow = true"
            @mouseleave="telShow = false"
          />
          <div class="tel-box" v-show="telShow">
            <img src="@/assets/tel-box.png" class="tel-box-img" alt="" />
          </div>
          <div class="tel" v-show="telShow">028-6019-1875</div>
        </div>
      </div>
    </div>
    <div class="top-icon-box2">
      <div class="icon hanle-link">
        <img
          src="@/assets/top.png"
          @click="handleTop"
          class="img-defalt"
          alt=""
        />
      </div>
    </div>

    <div class="foot">
      <div class="foot-info-box flex-center">
        <div class="foot-info-left-box">
          <div class="info-title">关于我们</div>
          <div class="left-info-box">
            <div class="info hanle-link" @click="linkto(0)">服务平台</div>
            <div class="info hanle-link" @click="linkto(1)">硬件机台</div>
          </div>
          <div class="left-info-box">
            <div class="info hanle-link" @click="linkto(2)">关于我们</div>
            <!-- <div class="info hanle-link" @click="linkto(3)">成功案例</div> -->
          </div>
        </div>
        <div class="foot-info-mode-box">
          <div class="info-title">联系我们</div>
          <div class="info-mode">电话：028-6019-1875</div>
          <div class="info-mode">
            地址：四川省成都市高新区天府三街希顿国际广场
          </div>
        </div>
        <div class="gzh-qr-box">
          <img src="@/assets/gwLogo.png" class="qr-img" />
          <div>官方公众号</div>
        </div>
      </div>
      <div class="foot-number" @click="linkto(4)">
        备案号：蜀ICP备18023793号
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      gotop: false,
      telShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      //注意不同浏览器之间的兼容性
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    handleTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    linkto(n) {
      if (n == 0) {
        console.log(this.$route);
        if (this.$route.path == "/server") {
          this.$router.push({
            path: "/server",
            query: {
              indexId: this.$route.query.indexId
                ? Number(this.$route.query.indexId) + 1
                : 1,
            },
          });
        } else {
          this.$router.replace({ path: "/server" });
        }
      }
      if (n == 1) {
        this.$router.push({
          path: "/server",
          query: {
            indexsId: this.$route.query.indexsId
              ? Number(this.$route.query.indexsId) + 1
              : 1,
          },
        });
        // this.$router.push({ path: "/server", query: { id: 12 } });
      }
      if (n == 2) {
        if (this.$route.path == "/about") {
          this.handleTop();
        } else {
          this.$router.replace({ path: "/about" });
        }
      }
      if (n == 3) {
        if (this.$route.path == "/case") {
          this.handleTop();
        } else {
          this.$router.replace({ path: "/case" });
        }
      }
      if (n == 4) {
        window.open("https://beian.miit.gov.cn");
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  min-width: 1200px;
}
.top-icon-box1 {
  position: sticky;
  right: 0;
  z-index: 100;
  bottom: 90px;
  width: 100%;
  display: flex;
  margin-bottom: 4px;
  justify-content: flex-end;
  .icon {
    width: 46px;
    height: 46px;
  }
  .icon-box {
    width: 46px;
    height: 46px;
    position: relative;

    .tel-box {
      width: 218px;
      height: 95px;
      position: absolute;
      top: -24px;
      left: -223px;
      z-index: 1;
      .tel-box-img {
        width: 218px;
        height: 95px;
      }
    }
    .tel {
      width: 218px;
      height: 95px;
      position: absolute;
      top: 0;
      left: -229px;
      z-index: 2;
      color: #333333;
      text-align: center;
      font-size: 22px;
    }
  }
}
.top-icon-box2 {
  position: sticky;
  right: 0;
  z-index: 100;
  bottom: 40px;
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .icon {
    width: 46px;
    height: 46px;
  }
}

.foot {
  width: 100%;
  background: #2d2f39;
  padding-top: 53px;
  .info-title {
    font-size: 18px;
    padding-bottom: 25px;
  }
  .foot-info-left-box {
    padding-left: 67px;
    box-sizing: border-box;
  }
  .foot-info-box {
    color: white;
    padding-bottom: 51px;
    .foot-info-left-box {
      .left-info-box {
        font-size: 14px;
        display: flex;
        .info {
          margin-right: 51px;
          margin-bottom: 16px;
        }
      }
    }
  }
  .foot-info-mode-box {
    margin: 0 10%;
    .info-mode {
      padding-bottom: 17px;
    }
  }

  .gzh-qr-box {
    .qr-img {
      width: 106px;
      height: 106px;
      margin-bottom: 16px;
    }
    width: 106px;
    text-align: center;
    font-size: 14px;
  }
  .foot-number {
    font-size: 15px;
    width: 100%;
    color: white;
    padding: 18px 0;
    text-align: center;
    border-top: 2px solid #121215;
    cursor: pointer;
  }
}

.top-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #2f2f2f47;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  .logo-box {
    width: 244px;
    height: 70px;
    .logo {
      width: 100%;
      height: 100%;
    }
  }
  .top-list-box {
    display: flex;
    .top-nav {
      font-size: 18px;
      color: white;
      width: 110px;
      text-align: center;
      height: 50px;
      line-height: 50px;
    }
    .top-nav-ative {
      background: #469dff;
      border-radius: 10px;
    }
  }
}
a {
  color: white;
  text-decoration-line: none !important;
}
a.router-link-active {
  background: #469dff;
  border-radius: 10px;
}
</style>
