<template>
  <div class="home">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <div class="banner-box">
      <div class="boxs" @mouseout="out" @mouseover="over">
        <img
          v-for="(item, index) in list"
          v-show="listIndex === index"
          :key="index"
          :src="item"
          alt=""
        />
        <img
          src="@/assets/home/left-icon.png"
          class="left hanle-link"
          @click="changePage(prevIndex)"
        />
        <ul>
          <li
            :class="{ color: index == listIndex }"
            v-for="(item, index) in list"
            @click="changePage(index)"
            :key="index"
          ></li>
        </ul>
        <img
          src="@/assets/home/right-icon.png"
          class="right hanle-link"
          @click="changePage(nextIndex)"
        />
      </div>
    </div>

    <div class="home-server-box">
      <div class="title-defalt flex-center">服 务 平 台</div>
      <div class="title-eg-defalt flex-center">SERVICE PLATFORM</div>
      <div class="flex-center">
        <div
          class="server-list-box hanle-link"
          @click="handleGo(index)"
          v-for="(item, index) in serverList"
          :key="index"
        >
          <div class="server-img-box">
            <img :src="item.img" class="img-defalt" />
          </div>
          <div class="server-title flex-center">{{ item.name }}</div>
          <div class="server-span flex-center">详情介绍</div>
        </div>
      </div>
    </div>

    <div class="home-about-box">
      <div class="title-defalt flex-center">关 于 我 们</div>
      <div class="title-eg-defalt flex-center">ABOUT US</div>
      <div class="flex-center home-about-info-bx">
        <div class="about-info-box">
          <div class="about-info-title">四川百川之星科技有限公司</div>
          <div class="about-xian"></div>
          <div class="text-justify">
            <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;四川百川之星科技有限公司成立于2018年初，总公司是以游乐设施设备为基础，
            从研发生产到销售一体化的公司。历经20年的奋斗，公司将业务范围延伸至全国各地以及国外，
            并向互联网区域全面进军。现已与万达集团，宝龙集团，保利影视，
            恒大集团等多家商业巨头达成战略合作。成为他们必不可少的战略合作供应商。 -->
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;真诚相待，快乐游戏，做我们自己引以为傲的游乐场，成长为更好的自己，这就是我们快乐工作的源泉。
            为尽可能发挥出团队的创造力而坚持不懈，营造出了极佳的团队氛围，提供给每个人充分的成长空间。
          </div>
          <div class="btn hanle-link" @click="handleGoM('about')">查看更多</div>
        </div>
        <div class="about-img-box">
          <img src="@/assets/home/img1.png" class="img-defalt" />
        </div>
      </div>
    </div>

    <div class="advantage-box">
      <div class="title-defalt flex-center">我 们 的 优 势</div>
      <div class="title-eg-defalt flex-center">OUR ADVANTAGE</div>
      <div class="flex-center">
        <div class="avantage-list-box">
          <div
            class="avantage-list"
            v-for="(item, index) in avantageList"
            :key="index"
          >
            <img :src="item.img" class="avantage-img" />
            <div class="avantage-font text-justify">{{ item.tips }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="case-box">
      <img src="@/assets/home/case-img.png" class="case-img" />
      <div class="case-title flex-center">成 功 案 例</div>
      <div class="case-eg-title flex-center">SUCCESSFUL CASE</div>
      <div class="case-img-box">
        <div v-for="(item, index) in caseList" :key="index" class="case-img1">
          <img :src="item.img" class="img-defalt" />
        </div>
      </div>
      <div class="case-btn hanle-link" @click="handleGoM('case')">更多案例</div>
    </div> -->

    <!-- <div class="partner-box">
      <div class="title-defalt flex-center">合 作 伙 伴</div>
      <div class="title-eg-defalt flex-center">COOPERATIVE PARTNER</div>
      <div class="flex-center">
        <img src="@/assets/home/server-img.png" class="partner-img" />
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import nav1 from "@/assets/home/nav1.png";
import nav2 from "@/assets/home/nav2.png";
import nav3 from "@/assets/home/nav3.png";
import nav4 from "@/assets/home/nav4.png";
import nav5 from "@/assets/home/nav5.png";
import nav6 from "@/assets/home/nav6.png";
import nav7 from "@/assets/home/nav7.png";
import nav8 from "@/assets/home/nav8.png";
import nav9 from "@/assets/home/nav9.png";
import nav10 from "@/assets/home/nav10.png";
import case1 from "@/assets/home/case1.png";
import case2 from "@/assets/home/case2.png";
import case3 from "@/assets/home/case3.png";
import case4 from "@/assets/home/case4.png";
import banner1 from "@/assets/home/banner1.png";
import banner2 from "@/assets/home/banner2.png";
import banner3 from "@/assets/home/banner3.png";
import banner4 from "@/assets/home/banner4.png";
import banner5 from "@/assets/home/banner5.png";

export default {
  name: "HomeView",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      serverList: [
        {
          name: "鑫花运营服务平台",
          img: nav1,
        },
        {
          name: "有Young小程序",
          img: nav2,
        },
        {
          name: "美团核验",
          img: nav3,
        },
        {
          name: "抖音核验",
          img: nav4,
        },
      ],
      avantageList: [
        {
          tips: "拥有专业的研发及运营双团队，经验丰富、才华出 众，紧密贴合市场需求，快速迭代，与时俱进。",
          img: nav5,
        },
        {
          tips: "拥有20年街机及游乐行业场地管理经验，以营收为 目标，运用逆向开发思维，打造出一款真正契合市 场需求的专业管理系统。",
          img: nav6,
        },
        {
          tips: "提供会员、机台、营收等各种数据分析，一键营销，让您的投资与回报能得到充分的保障。",
          img: nav7,
        },
        {
          tips: "建立独特的积分消费体系，结合平台优势，整合周边资源，利用会员管理体系，实现精准营销，提高用户粘性，增强用户的体验感。",
          img: nav8,
        },
        {
          tips: "通过线上线下异业联盟，结合独有的商业模式，运用爆款活动，实现裂变营销，完成会员积累，实现反向导流。",
          img: nav9,
        },
        {
          tips: "提供专业的运营方案、一对一运营指导，通过消费场景塑造，模块氛围打造，新运营模式方案等，确保系统发挥最大效益。",
          img: nav10,
        },
      ],
      caseList: [
        {
          img: case1,
        },
        {
          img: case2,
        },
        {
          img: case3,
        },
        {
          img: case4,
        },
      ],
      listIndex: 0, //默认显示第几张图片
      timer: null, //定时器
      list: [banner1, banner2, banner3, banner4, banner5],
    };
  },
  computed: {
    //上一张
    prevIndex() {
      if (this.listIndex == 0) {
        return this.list.length - 1;
      } else {
        return this.listIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.listIndex == this.list.length - 1) {
        return 0;
      } else {
        return this.listIndex + 1;
      }
    },
  },
  methods: {
    changePage(index) {
      this.listIndex = index;
    },
    //移除
    out() {
      this.setTimer();
    },
    //移入
    over() {
      clearInterval(this.timer);
    },
    //1秒切图
    setTimer() {
      this.timer = setInterval(() => {
        this.listIndex++;
        if (this.listIndex == this.list.length) {
          this.listIndex = 0;
        }
      }, 5000);
    },
    handleGo(index) {
      const id = index + 1;
      this.$router.push({ path: "/server", query: { id: id } });
    },
    handleGoM(name) {
      if (name == "case") {
        this.$router.push({ path: "/case" });
      }
      if (name == "about") {
        this.$router.push({ path: "/about" });
      }
    },
  },
  created() {
    //定时器
    this.setTimer();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.banner-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 87px;
  .boxs {
    position: relative;
    width: 100%;
    height: 1078px;
    transition: all 1s ease-in-out;
    img {
      width: 100%;
      height: 100%;
      z-index: 100;
      transition: all 1s ease-in-out;
    }
    p {
      cursor: pointer;
      color: white;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      // background: rgba(0, 0, 0, 0.5);
    }
    .left {
      position: absolute;
      top: 50%;
      left: 116px;
      width: 30px;
      height: 50px;
    }
    .right {
      position: absolute;
      top: 50%;
      right: 116px;
      width: 30px;
      height: 50px;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      width: 150px;
      height: 20px;
      bottom: 52px;
      right: 0;
      left: 0;
      margin: 0 auto;
      .color {
        background: white;
        color: white;
      }
      li {
        cursor: pointer;
        width: 10px;
        height: 10px;
        background: #ffffff61;
        border-radius: 50%;
      }
    }
  }
}
.home-server-box {
  width: 100%;
  padding-bottom: 97px;
  .server-list-box {
    width: 276px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    margin-right: 32px;
    margin-top: 76px;
    .server-img-box {
      width: 274px;
      height: 278px;
    }
    .server-title {
      font-size: 20px;
      color: #333333;
      padding-top: 44px;
    }
    .server-span {
      font-size: 16px;
      color: #999999;
      padding: 20px 0 40px 0;
    }
  }
  .server-list-box:hover {
    box-shadow: 0px 0px 18px #cdcdcd;
  }
  .server-list-box:hover {
    .server-title {
      color: #469dff !important;
    }
    .server-span {
      color: #469dff !important;
    }
  }
}

.home-about-box {
  width: 100%;
  background: #f3f6f9;
  padding: 40px 0;
  .home-about-info-bx {
    padding: 100px 0 92px 0;
  }
  .about-xian {
    width: 528px;
    height: 2px;
    background: #469dff;
    margin: 17px 0 28px 0;
  }
  .about-info-box {
    width: 528px;
    margin-right: 74px;
    box-sizing: border-box;
    font-size: 21px;
    color: #333333;
    line-height: 36px;
    .about-info-title {
      font-size: 28px;
    }
    .btn {
      font-size: 20px;
      color: white;
      background: #469dff;
      border-radius: 45px;
      text-align: center;
      width: 191px;
      height: 49px;
      margin-top: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .about-img-box {
    width: 598px;
    height: 486px;
  }
}

.advantage-box {
  padding: 75px 0 47px 0;
  .avantage-list-box {
    width: 1227px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 55px;
    .avantage-list {
      width: 382px;
      margin-right: 27px;
      .avantage-img {
        width: 382px;
        height: 260px;
      }
      .avantage-font {
        font-size: 17px;
        color: #4d4d4d;
        min-height: 62px;
        padding: 24px 0 36px 0;
      }
    }
  }
}

.case-box {
  width: 100%;
  height: 602px;
  position: relative;
  .case-img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }
  .case-title {
    position: absolute;
    z-index: 1;
    top: 79px;
    left: 0;
    width: 100%;
    font-size: 40px;
    color: #469dff;
    font-weight: bold;
  }
  .case-eg-title {
    position: absolute;
    z-index: 1;
    top: 138px;
    left: 0;
    width: 100%;
    color: #ffffff;
    font-size: 18px;
    font-family: Source Han Sans CN;
  }
  .case-img-box {
    position: absolute;
    z-index: 1;
    top: 218px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 1212px;
    height: 194px;
    display: flex;
    .case-img1 {
      width: 290px;
      height: 194px;
      margin-right: 13px;
    }
  }
  .case-btn {
    position: absolute;
    z-index: 1;
    bottom: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 130px;
    height: 40px;
    border: 1px solid #469dff;
    border-radius: 4px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #469dff;
    line-height: 36px;
    text-align: center;
  }
}

.partner-box {
  width: 100%;
  padding-top: 79px;
  .partner-img {
    width: 1200px;
    height: 424px;
    margin: 67px 0 88px 0;
  }
}
</style>
